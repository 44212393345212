<template>
<section class="content">
    <div class="container-fluid">
    <Alert page="dashboard" position="top" />
    <div class="alert alert-success fade show" role="alert" v-bind:class="{ 'skeleton-item': loading }" v-if="true">
        <div class="row justify-content-between">
            <div class="col-auto my-2">
                <div v-if="!dataCashback.success">
                    <i class="fa fa-1x fa-shopping-cart"></i>  Kamu belum kirim paket di bulan ini, Klik tombol <b>Buat Pesanan</b> untuk mulai mengirim paket
                </div>
                <div v-if="dataCashback.success">
                    <i class="fas fa-1x fa-trophy"></i>  Sejak pakai BosCOD, kamu udah hemat <b>Rp {{ formatMoney(dataCashback.total) }}</b> di periode ini
                </div>
            </div>
            <div class="col-auto">
                <button type="button" href="javascript:" v-on:click="showAffiliate = !showAffiliate" class="btn btn-light text-success"><b>Ajak Temanmu </b><i class="fa fa-chevron-right"></i></button>
            </div>
        </div>
    </div>
    <div>
        <!-- PERFORMANCE -->
        <div class="card" v-if="isShipper">
            <div class="card-body">
                <div class="row justify-content-between">
                    <div class="col-sm-auto">
                        <div class="text-secondary">
                            <h2 class="float-left"></h2>
                            <div class="mb-3">
                                <h4 class="m-0"> Halo <strong>{{ username }} !</strong></h4>
                                <div>Ayo kirimkan paketmu sekarang</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                <div class="col-sm-12 row">
                    <div class="col-sm-3">
                    <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                        <i class="hxf hxf-delivery text-info" style="font-size: 24px;"></i>
                        <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Total Kiriman</h6>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                        <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ !dataPerformance.total_kiriman ? 0 : dataPerformance.total_kiriman }}</span>
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-3">
                    <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                        <i class="far fa-check-circle fa-2x" style="color: lawngreen;"></i>
                        <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Sukses</h6>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                        <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ !dataPerformance.rasio_sukses ? 0 : dataPerformance.rasio_sukses }}%</span>
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-3">
                    <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                        <i class="fas fa-undo-alt fa-2x" style="color: red;"></i>
                        <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Retur</h6>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                        <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ !dataPerformance.rasio_retur ? 0 : dataPerformance.rasio_retur }}%</span>
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-3">
                    <div class="btn-group btn-sm btn-block">
                        <button
                        type="button"
                        class="btn btn-primary btn-lg dropdown-toggle dropdown-icon"
                        data-toggle="dropdown"
                        >
                        <i class="fas fa-plus"></i> Buat Pesanan
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                        <router-link to="/order/create/single" class="dropdown-item">
                            <i class="fas fa-edit"></i> Input Manual
                        </router-link>
                        <router-link v-if="!isMobile" to="/order/create" class="dropdown-item">
                            <i class="fas fa-edit"></i> Input Manual Batch
                        </router-link>
                        <router-link to="/order/import" class="dropdown-item">
                            <i class="fas fa-upload"></i> Import
                        </router-link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!--FILTER-->
        <div class="row justify-content-end mt-4">
        <div class="col-md-4 col-xs-12">
            <div class="row">
                <div class="col-12 mb-2">
                    <div class="input-group">
                    <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                    />
                    <div class="input-group-append">
                    <div class="input-group-text" style="background-color: #fff">
                        <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        ></span>
                        <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                    </div>
                </div>
                <div class="col-12">
                    <select class="form-control" @change="kurirChange($event)">
                        <option value="0" selected>Semua kurir</option>
                        <option v-for="courier in totalCourier" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id">
                            {{ courier.nama_kurir }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        </div>

        <!--STATISTIC-->

        <div id="_sectionStatusPersiapan">
            <!--PERSIAPAN-->
            <div class="row mt-3">
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="fas fa-box"></i></h5>
                        <h5 class="info-box-text">Persiapan</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            // name: 'Riwayat',
                            name: 'Draft Kiriman',
                            // params: {filter: { draft: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-draft text-primary" style="font-size: 24px;"></i><h5 class="info-text">Draft</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPersiapan.draft ? 0 : dataPersiapan.draft }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { rpick: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-request-pickup text-primary" style="font-size: 24px;"></i><h5 class="info-text">Request Pickup</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPersiapan.reqPickup ? 0 : dataPersiapan.reqPickup }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { canc: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fa fa-ban text-primary" style="font-size: 24px;"></i><h5 class="info-text">Cancel Pesanan</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPersiapan.cancel ? 0 : dataPersiapan.cancel }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>

                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { err: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fa fa-ban text-primary" style="font-size: 24px;"></i><h5 class="info-text">Error</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPersiapan.error ? 0 : dataPersiapan.error }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="_sectionStatusPengiriman">
            <div class="row mt-3">
                <!--PENGIRIMAN-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="fas fa-car"></i></h5>
                        <h5 class="info-box-text">Pengiriman</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { delgo: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-delivery text-primary" style="font-size: 24px;"></i><h5 class="info-text">Pengiriman</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPengiriman.delivery ? 0 : dataPengiriman.delivery }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { deler: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fa fa-exclamation-triangle text-primary" style="font-size: 24px;"></i><h5 class="info-text">Problem</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPengiriman.problem ? 0 : dataPengiriman.problem }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { delok: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-delivered text-primary" style="font-size: 24px;"></i><h5 class="info-text">Telah Diterima</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataPengiriman.delivered ? 0 : dataPengiriman.delivered }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { retgo: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-returned text-primary" style="font-size: 24px;"></i><h5 class="info-text">Proses Retur</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataRetur.returngo ? 0 : dataRetur.returngo }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="_sectionStatusRetur">
            <div class="row mt-3">
                <!--RETUR-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="fas fa-undo-alt"></i></h5>
                        <h5 class="info-box-text">Retur</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { retok: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-returned text-primary" style="font-size: 24px;"></i><h5 class="info-text">Diserahkan</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading4 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataRetur.returned ? 0 : dataRetur.returned }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { retacc: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-returned text-primary" style="font-size: 24px;"></i><h5 class="info-text">Diterima</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading4 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataRetur.retReceived ? 0 : dataRetur.retReceived }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="_sectionStatusTidakBergerak">
            <div class="row mt-3">
                <!--TIDAK BERGERAK-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
                        <h5 class="info-box-text">Tidak Bergerak</h5>
                    </div>
                    </div>
                    <button class="btn btn-primary btn-lg text-bold" id="showBtn1" @click="loadStatusNotMove">Tampilkan..</button>
                    <div class="col-12 col-sm-10 row margin-0 notmove-blur" id="showBlur">
                        <div class="col-12 col-sm-6 col-md-3">
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { nmove5: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                            }">
                            <div class="info-box mb-3 vertical-center box-border row">
                                <div class="row" style="padding-left: 10px;">
                                <i class="hxf hxf-nochange text-primary" style="font-size: 24px;"></i><h5 class="info-text">>5 Hari</h5>
                                </div>
                                <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                                <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading5 }" style="padding: 0px;">
                                    <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataNotMove.notMove5 ? 0 : dataNotMove.notMove5 }}</h5>
                                </div>
                                </div>
                            </div>
                            </router-link>
                            <!-- /.info-box -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <router-link
                            :to="{
                                name: 'Riwayat',
                                params: {filter: { nmove10: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                            }"
                            >
                            <div class="info-box mb-3 vertical-center box-border row">
                                <div class="row" style="padding-left: 10px;">
                                <i class="hxf hxf-nochange text-primary" style="font-size: 24px;"></i><h5 class="info-text">>10 Hari</h5>
                                </div>
                                <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                                <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading5 }" style="padding: 0px;">
                                    <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataNotMove.notMove10 ? 0 : dataNotMove.notMove10 }}</h5>
                                </div>
                                </div>
                            </div>
                            </router-link>
                            <!-- /.info-box -->
                        </div>
                        
                        <div class="col-12 col-sm-6 col-md-3">
                            <router-link
                            :to="{
                                name: 'Riwayat',
                                params: {filter: { nmove14: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                            }"
                            >
                            <div class="info-box mb-3 vertical-center box-border row">
                                <div class="row" style="padding-left: 10px;">
                                <i class="hxf hxf-nochange text-primary"></i><h5 class="info-text">>14 hari</h5>
                                </div>
                                <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                                <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading5 }" style="padding: 0px;">
                                    <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !dataNotMove.notMove14 ? 0 : dataNotMove.notMove14 }}</h5>
                                </div>
                                </div>
                            </div>
                            </router-link>
                            <!-- /.info-box -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="_sectionTicket" class="mb-4">
            <div class="row mt-3">
                <!--TICKET-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
                        <h5 class="info-box-text">Ticket & Claim</h5>
                    </div>
                    </div>
                    <button class="btn btn-primary btn-lg text-bold" id="showBtn2" @click="loadStatusTicket">Tampilkan..</button>
                    <div class="col-12 col-sm-10 row margin-0 ticket-blur" id="showBlur">
                        <div class="col-12 col-sm-6 col-md-3">
                            <router-link
                            :to="{
                                name: 'List Ticket',
                                params: {filter:{ open: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                            }"
                            >
                            <div class="info-box mb-3 vertical-center box-border row">
                                <div class="row" style="padding-left: 10px;">
                                <i class="fas fa-inbox text-primary" style="font-size: 24px;"></i><h5 class="info-text">Open</h5>
                                </div>
                                <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                                <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading6 }" style="padding: 0px;">
                                    <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !allTicket.open ? 0 : allTicket.open }}</h5>
                                </div>
                                </div>
                            </div>
                            </router-link>
                            <!-- /.info-box -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <router-link
                            :to="{
                                name: 'List Ticket',
                                params: {filter: { closed: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                            }"
                            >
                            <div class="info-box mb-3 vertical-center box-border row">
                                <div class="row" style="padding-left: 10px;">
                                <i class="fas fa-check-double text-primary" style="font-size: 24px;"></i><h5 class="info-text">Closed</h5>
                                </div>
                                <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                                <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading6 }" style="padding: 0px;">
                                    <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !allTicket.closed ? 0 : allTicket.closed }}</h5>
                                </div>
                                </div>
                            </div>
                            </router-link>
                            <!-- /.info-box -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <router-link
                            :to="{
                                name: 'List Claim',
                                params: {filter: { retclm: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                            }"
                            >
                            <div class="info-box mb-3 vertical-center box-border row">
                                <div class="row" style="padding-left: 10px;">
                                <i class="hxf hxf-rtk text-primary" style="font-size: 24px;"></i><h5 class="info-text">Claim</h5>
                                </div>
                                <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                                <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading6 }" style="padding: 0px;">
                                    <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ !allTicket.retClaim ? 0 : allTicket.retClaim }}</h5>
                                </div>
                                </div>
                            </div>
                            </router-link>
                            <!-- /.info-box -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="_sectionChart" class="mb-4">
            <label class="control-label my-1 ml-0 mt-4">
                <h5>Grafik terkini</h5>
            </label>
            <div class="row mt-3">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <button class="btn btn-primary btn-lg text-bold" id="showBtn3" @click="loadPieChart">Tampilkan..</button>
                    <div class="row" id="chart1Empty" style="display: none;">
                        <div class="col-lg-12 d-flex justify-content-center">
                            <div class="card bg-transparent my-auto mw-100" style="width: 17rem;">
                                <div class="card-body">
                                    <img src="@/assets/img/Analyze-amico.svg" class="card-img-top" alt="cover next step">
                                    <h5 class="text-center">Belum ada pencapaian</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-wrapper chart1">
                        <div class="info-box mb-3 chart1-blur" id="showBlur">
                            <div class="overlay" v-if="loading7"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
                            <canvas ref="myChart" id="myChart"></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 d-none">
                    <button class="btn btn-primary btn-lg text-bold" id="showBtn4" @click="loadBarChart">Tampilkan..</button>
                    <div class="row" id="chart2Empty" style="display: none;">
                        <div class="col-lg-12 d-flex justify-content-center">
                            <div class="card bg-transparent my-auto mw-100" style="width: 17rem;">
                                <div class="card-body">
                                    <img src="@/assets/img/Analyze-amico.svg" class="card-img-top" alt="cover next step">
                                    <h5 class="text-center">Belum ada pencapaian</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-wrapper chart2">
                        <div class="info-box chart2-blur" id="showBlur">
                            <div class="overlay" v-if="loading8"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
                            <canvas ref="myChart2" id="myChart2"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="_sectionCashback" class="mb-4">
            <div class="row mt-3">
                <label class="control-label my-1 ml-0 mt-4">
                    <h5>Kamu telah Hemat Ongkir</h5>
                </label>
                <div class="col-md-12 cashback-empty" style="display: none;">
                    <div class="card artikel right shadow-sm">
                        <div class="card-body mr-0 ml-0 d-flex justify-content-center">
                            <div class="row">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-4">
                                    <div class="card my-auto mw-100" style="width: 17rem;">
                                        <div class="card-body">
                                            <img src="@/assets/img/Savingmoney-amico.svg" class="card-img-top" alt="cover next step">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 my-auto">
                                    <div class="card">
                                        <div class="card-body">
                                            <h2 class="card-title mb-3" style="font-size: 1.1rem;">
                                                <strong>Belum ada transaksi</strong>
                                            </h2>
                                            <p class="card-text">Kamu belum ada transaksi saat ini. Ayo kirimkan paketmu sekarang dan dapatkan diskon ongkir yang lebih hemat.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 cashback-card">
                    <button class="btn btn-primary btn-lg text-bold" id="showBtn5" @click="loadStatusCashback">Tampilkan..</button>
                    <div class="info-box mb-3 cashback-blur" id="showBlurCashback">
                    <div class="info-box-content">
                        <div class="row">
                            <div class="p-1"></div>
                            <div class="col-12">
                                <div class="overlay-wrapper">
                                    <div class="row justify-content-center">
                                        <div class="overlay" v-if="loading9"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
                                        <div class="col col-md-4 col-xs-12 d-flex justify-content-center" v-for="item in dataCashback.data" v-bind:key="item.code">
                                        <!-- <div class="overlay-wrapper"> -->
                                            <div class="info-box">
                                                <img height="40" v-bind:src="url + '/assets/img/'+item.code+'.png'"/>
                                                <div class="info-box-content" >
                                                    <div class="overlay" v-if="loading9"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
                                                    <h4 class="info-box-number my-2">
                                                    Rp {{ formatMoney(item.nilai_ongkir*1) }}
                                                    </h4>
                                                </div>
                                                <div class="p-4"></div>
                                            </div>
                                        <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="_sectionFinance">
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="card artikel right shadow-sm">
                        <div class="card-body mr-0 ml-0 d-flex justify-content-center">
                            <div class="row">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-4">
                                    <div class="card my-auto mw-100" style="width: 17rem;">
                                        <div class="card-body">
                                            <img src="@/assets/img/E-Wallet-amico.svg" class="card-img-top" alt="cover next step">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 my-auto">
                                    <div class="card">
                                        <div class="card-body">
                                            <h2 class="card-title mb-3" style="font-size: 1.1rem;">
                                                <strong>Kamu dapat melihat ringkasan finansial disini</strong>
                                            </h2>
                                            <p class="card-text">Pantau perkembangan bisnis kamu dengan melihat ringkasan finansial selama menggunakan bosCOD.</p>
                                            <button class="btn btn-outline-primary" :disabled="loadedFinance" v-on:click="loadFinance">
                                                Lihat Selengkapnya <span v-if="loadedFinance" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay" v-if="loadedFinance"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <!-- fix for small devices only -->
    <div class="clearfix hidden-md-up"></div>
    <div class="p-2"></div>
    </div>
    <Affiliator :show="showAffiliate" />
</section>
</template>
<script>
import Daterangepicker from "daterangepicker";
import $ from "jquery";
import Chart from "chart.js";
import { authFetch, auth, formatCurrency, isMobile } from "@/libs/hxcore";
import Affiliator from "@/dialog/Affiliator";
import Alert from "@/dialog/Alert";
import moment from "moment";
import Vue from 'vue';
import "daterangepicker/daterangepicker.css";
var myChart = null;
var myChart2 = null;
export default {
    name: "Home",
    data: function () {
        return {
          isShipper: false,
          roles: "",
          dateStart: moment().day(-31).format("YYYY-MM-DD"),
          dateEnd: moment().add(2, 'd').format("YYYY-MM-DD"),
          allTicket: {},
          dataPerformance: {
              total_kiriman: 0,
              rasio_sukses: 0,
              rasio_retur: 0
          },
          dataPersiapan: {},
          dataPengiriman: {},
          dataRetur: {},
          dataNotMove: {},
          dataCashback: {},
        //   dataFinance: {},
          totalCourier: [],
          courier_id: '0',
          url: window.$apiUrl,
          dataCourierPie: {
            type: "pie",
            data: [],
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Data Courier",
                },
              },
            },
          },
          dataCourierBar: {
            type: "bar",
            data: [],
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Data",
                },
              },
            },
          },
          dt: {
            start: "",
            end: "",
          },
          infoBar: [],
          loading: false,
          loading2: false,
          loading3: false,
          loading4: false,
          loading5: false,
          loading6: false,
          loading7: false,
          loading8: false,
          loading9: false,
          loadedStatus: false,
          loadedTicket: false,
          loadedChart: false,
          loadedChart2: false,
          loadedFinance: false,
          loadedCashback: false,
          check: false,
          showAffiliate: false,
          infoVerifikasi: "",
          styleVerifikasi: {},
          disableVerifikasi: true,
        };
    },
    components: {
        Affiliator,
        Alert,
    },
    created: function () {
        this.roles = this.$route.params.roles;
        var info = auth.user();
        if (info) {
          this.username = info.display_name;
          this.isShipper = info.role_id > 10;
        }

        if(Vue.config.appHost == 'xpress')
            this.check = false;
        else this.check = true;

        authFetch("/order/courier/courierplus")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.totalCourier = js.data;
                $('.loading-overlay').removeClass('show');
            });
        // this.loadStatusPersiapan();
        // this.loadStatusPengiriman();
        // this.loadStatusRetur();
    },
    computed: {
        isReady: function () {
            !this.loading;
            !this.loading2;
            !this.loading3;
            !this.loading4;
            !this.loading5;
            !this.loading6;
            !this.loading7;
            !this.loading8;
            return !this.loading9;
        },
        isMobile: function(){return isMobile();},
    },
    methods: {
        // handleScroll: ,
        kurirChange(event) {
            let id = event.target.value;
            this.courier_id = id;
            // this.loadStatusPersiapan();
            // this.loadStatusPengiriman();
            this.loadSummary();
            this.loadedTicket = false;
            this.loadedChart = false;
            this.loadedChart2 = false;
            this.loadedCashback = false;

            $('#chart1Empty').css('display', 'none');
            $('#myChart').css('display', 'block');
            $('.overlay-wrapper.chart1').css('display', 'block');

            $('#chart2Empty').css('display', 'none');
            $('#myChart2').css('display', 'block');
            $('.overlay-wrapper.chart2').css('display', 'block');

            $('.cashback-card').css('display', 'block');
            $('.cashback-empty').css('display', 'none');

            $('.notmove-blur').attr('id', 'showBlur');
            $('.ticket-blur').attr('id', 'showBlur');
            $('.chart1-blur').attr('id', 'showBlur');
            $('.chart2-blur').attr('id', 'showBlur');
            $('.cashback-blur').attr('id', 'showBlurCashback');
            let blurbg = require(`../assets/img/blurbg.jpg`);
            $(".chart1-blur").css("background-image", 'url('+ blurbg +')');
            $(".chart2-blur").css("background-image", 'url('+ blurbg +')');
            $(".cashback-blur").css("background-image", 'url('+ blurbg +')');

            $("#showBtn1").css("display", "block");
            $("#showBtn2").css("display", "block");
            $("#showBtn3").css("display", "block");
            $("#showBtn4").css("display", "block");
            $("#showBtn5").css("display", "block");
        },
        loadSummary() {
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
            this.loading = true;
            this.loading2 = true;
            this.loading3 = true;
            this.loading4 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/summary?${search}`).then((res) => {
                res.json().then((json) => {
                    this.loading = false;
                    this.loading2 = false;
                    this.loading3 = false;
                    this.loading4 = false;
                    if (json.success) {
                        console.log('json.data',json.data.total_kiriman);
                        this.dataPersiapan['draft'] = json.data.draft;
                        this.dataPersiapan['reqPickup'] = json.data.reqPickup;
                        this.dataPersiapan['cancel'] = json.data.cancel;
                        this.dataPersiapan['error'] = json.data.error;

                        this.dataPengiriman['total_kiriman'] = json.data.total_kiriman;
                        this.dataPengiriman['delivery'] = json.data.delivery;
                        this.dataPengiriman['problem'] = json.data.problem;
                        this.dataPengiriman['delivered'] = json.data.delivered;

                        this.dataRetur['returned'] = json.data.returned;
                        this.dataRetur['retReceived'] = json.data.retReceived;
                        this.dataRetur['returngo'] = json.data.returngo;

                        let delivered = json.data.delivered * 1;
                        this.dataPerformance.total_kiriman = json.data.total_kiriman * 1;
                        this.dataPerformance.rasio_sukses = this.dataPerformance.total_kiriman == 0 ? 0 : (delivered / this.dataPerformance.total_kiriman * 100).toFixed(1);
                        
                        console.log(this.dataPerformance.rasio_sukses);

                        let returngo = parseInt(json.data.returngo);
                        let returned = parseInt(json.data.returned);
                        let retReceived = parseInt(json.data.retReceived);
                        let rasio_retur = (returngo + returned + retReceived) / this.dataPerformance.total_kiriman * 100;
                        this.dataPerformance.rasio_retur = this.dataPerformance.total_kiriman == 0 ? 0 : rasio_retur.toFixed(2);
                    }
                });
            });
        },
        loadStatusPersiapan() {
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
            this.loading2 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/persiapan?${search}`).then((res) => {
                res.json().then((json) => {
                    this.loading2 = false;
                    if (json.success) {
                        this.dataPersiapan = json.status;
                    }
                });
            });
        },
        loadStatusPengiriman: function () {
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
            this.loading = true;
            this.loading3 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/pengiriman?${search}`).then((res) => {
                res.json().then((json) => {
                    this.loading = false;
                    this.loading3 = false;
                    if (json.success) {
                        this.dataPengiriman = json.status;
                        let delivered = json.status.delivered * 1;

                        this.dataPerformance.total_kiriman = json.status.total_kiriman * 1;
                        this.dataPerformance.rasio_sukses = this.dataPerformance.total_kiriman == 0 ? 0 : (delivered / this.dataPerformance.total_kiriman * 100).toFixed(1);

                        this.loadStatusRetur();
                    }
                });
            });
        },
        loadStatusRetur: async function () {
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
            this.loading = true;
            this.loading4 = true;
            var search = new URLSearchParams(params);
            await authFetch(`/dashboard/retur?${search}`).then((res) => {
                res.json().then((json) => {
                    this.loading = false;
                    this.loading4 = false;
                    if (json.success) {
                        this.dataRetur = json.status;

                        let returngo = parseInt(json.status.returngo);
                        let returned = parseInt(json.status.returned);
                        let retReceived = parseInt(json.status.retReceived);

                        let rasio_retur = (returngo + returned + retReceived) / this.dataPerformance.total_kiriman * 100;

                        this.dataPerformance.rasio_retur = this.dataPerformance.total_kiriman == 0 ? 0 : rasio_retur.toFixed(2);
                    }
                });
            });
        },
        loadStatusNotMove: function(e){
            /* Hide button show more */
            var btn = document.getElementById(e.target.id);
            if (btn.style.display === "none") {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }

            /* Hide blur */
            $('.notmove-blur').removeAttr('id');

            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
            this.loading5 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/not_move?${search}`).then((res) => {
                res.json().then((json) => {
                    this.loading5 = false;
                    if (json.success) {
                        this.dataNotMove = json.status;
                    }
                });
            });
        },
        loadStatusTicket: function(e){
            /* Hide button show more */
            var btn = document.getElementById(e.target.id);
            if (btn.style.display === "none") {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }

            /* Hide blur */
            $('.ticket-blur').removeAttr('id');
            // if (this.loadedTicket == true) {
                // return;
            // } else {
                this.loadedTicket = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
                this.loading6 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/statistic_tickets?${search}`)
                    .then((res) => {
                        if (res.status === 201) {
                        } else if (res.status === 400) {
                        }
                        return res.json();
                    })
                    .then((js) => {
                        this.loading6 = false;
                        this.allTicket = js.data;
                    });
            },
        loadPieChart: function(e){
            /* Hide button show more */
            var btn = document.getElementById(e.target.id);
            if (btn.style.display === "none") {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }

            /* Hide blur */
            $('.chart1-blur').removeAttr('id');
            $(".chart1-blur").css("background-image", 'url("")');

            if (this.loadedChart == true) {
                return;
            } else {
                this.loadedChart = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
                this.loading7 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/pie_chart?${search}`).then((res) => {
                    res.json().then((json) => {
                        this.loading7 = false;
                        if (json.success) {
                            this.dataCourierPie.data = json.pieChart;
                            let countData = json.pieChart.datasets[0].data;
                                // alert(countData.length);return;
                            if (countData.length < 1) {
                                // chart1Empty
                                $('#chart1Empty').css('display', 'block');
                                $('#myChart').css('display', 'none');
                                $('.overlay-wrapper.chart1').css('display', 'none');
                            } else {
                                $('#chart1Empty').css('display', 'none');
                                $('#myChart').css('display', 'block');
                                $('.overlay-wrapper.chart1').css('display', 'block');
                                myChart.update();
                            }
                        }
                    });
                });
            }
        },
        loadBarChart: function(e){
            /* Hide button show more */
            var btn = document.getElementById(e.target.id);
            if (btn.style.display === "none") {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }

            /* Hide blur */
            $('.chart2-blur').removeAttr('id');
            $(".chart2-blur").css("background-image", 'url("")');

            if (this.loadedChart2 == true) {
                return;
            } else {
                this.loadedChart2 = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
                this.loading8 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/bar_chart?${search}`).then((res) => {
                    res.json().then((json) => {
                        this.loading8 = false;
                        if (json.success) {
                            this.dataCourierBar.data = json.barChart;

                            let countData = json.barChart.datasets;
                            if (countData.length < 1) {
                                // chart2Empty
                                $('#chart2Empty').css('display', 'block');
                                $('#myChart2').css('display', 'none');
                                $('.overlay-wrapper.chart2').css('display', 'none');
                            } else {
                                $('#chart2Empty').css('display', 'none');
                                $('#myChart2').css('display', 'block');
                                $('.overlay-wrapper.chart2').css('display', 'block');
                                myChart2.update();
                            }
                        }
                    });
                });
            }
        },
        loadStatusCashback: function(e){
            /* Hide button show more */
            var btn = document.getElementById(e.target.id);
            if (btn.style.display === "none") {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }

            /* Hide blur */
            $('.cashback-blur').removeAttr('id');
            $(".cashback-blur").css("background-image", 'url("")');
            // if (this.loadedCashback == true) {
            //     return;
            // } else {
                this.loadedCashback = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id };
                this.loading9 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/cashback?${search}`).then((res) => {
                    res.json().then((json) => {
                        this.loading9 = false;
                    // if (json.success) {
                        this.dataCashback = json;

                        if (this.dataCashback.total < 1) {
                            $('.cashback-card').css('display', 'none');
                            $('.cashback-empty').css('display', 'block');
                        } else {
                            $('.cashback-card').css('display', 'block');
                            $('.cashback-empty').css('display', 'none');
                        }

                    // }
                    });
                });
            // }
        },
        formatMoney(val) {
            return formatCurrency(val);
        },
        filterRoles(role) {
            return this.roles.filter(n=>n===role).length===0?false:true; 
        },
        loadFinance() {
            this.loadedFinance = true;
            this.$router.push('/dashboard/finance');
        }
    },
    mounted() {
        this.$gtag.event(`page_view`, {
            'event_name': 'Dashboard'
        });

        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', '//embed.typeform.com/next/embed.js')
        document.head.appendChild(recaptchaScript)
        let self = this;

        // self.loadStatusPersiapan();
        // self.loadStatusPengiriman();
        // self.loadStatusRetur();
        self.loadSummary();

        //daterange all
        new Daterangepicker(
            this.$refs.daterange,
            {
                startDate: moment().day(-31),
                endDate: moment().add(2, 'd'),
                locale: {
                    format: 'DD/MM/YYYY'
                },
                minDate:moment().day(-90),
            },
            function (d1, d2) {
                self.dateStart = d1.format("YYYY-MM-DD");
                self.dateEnd = d2.format("YYYY-MM-DD");
                // self.loadStatusPersiapan();
                // self.loadStatusPengiriman();
                self.loadSummary();
                self.loadedTicket = false;
                self.loadedChart = false;
                self.loadedChart2 = false;
                self.loadedCashback = false;

                $('#chart1Empty').css('display', 'none');
                $('#myChart').css('display', 'block');
                $('.overlay-wrapper.chart1').css('display', 'block');

                $('#chart2Empty').css('display', 'none');
                $('#myChart2').css('display', 'block');
                $('.overlay-wrapper.chart2').css('display', 'block');

                $('.cashback-card').css('display', 'block');
                $('.cashback-empty').css('display', 'none');

                $('.notmove-blur').attr('id', 'showBlur');
                $('.ticket-blur').attr('id', 'showBlur');
                $('.chart1-blur').attr('id', 'showBlur');
                $('.chart2-blur').attr('id', 'showBlur');
                $('.cashback-blur').attr('id', 'showBlurCashback');

                let blurbg = require(`../assets/img/blurbg.jpg`);
                $(".chart1-blur").css("background-image", 'url('+ blurbg +')');
                $(".chart2-blur").css("background-image", 'url('+ blurbg +')');
                $(".cashback-blur").css("background-image", 'url('+ blurbg +')');

                $("#showBtn1").css("display", "block");
                $("#showBtn2").css("display", "block");
                $("#showBtn3").css("display", "block");
                $("#showBtn4").css("display", "block");
                $("#showBtn5").css("display", "block");
            }
        );

        var ctx = this.$refs.myChart.getContext("2d");
        var ctx2 = this.$refs.myChart2.getContext("2d");
        myChart = new Chart(ctx, this.dataCourierPie);
        myChart2 = new Chart(ctx2, this.dataCourierBar);
    }
};
</script>
<style>
#nominal{
  text-align: right;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /*text-align: center;*/
}

router-link {
  background-color: black;
  color: black;
}

a, a:hover {
  color: black;
}

.text-center-v {
  align-self: center;
}

.text-padding-12 {
  padding-top: 14px;
  overflow-wrap: anywhere;
}

@media (min-width: 300px) and (max-width: 1023px) {
  .text-right {
    text-align: left !important;
  }

  .text-padding-12 {
    padding-top: 0px;
    overflow-wrap: anywhere;
  }
}

.text-light {
  color: white;
}
.vertical-center {
  margin: 0;
  position: relative;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.box-border {
  border: 1.5px #C4C4C4 solid;
}
.info-box .info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}
.text-higher {
    font-size: large;
}
.info-text{
    color: initial;
    padding: 3px;
}
.cat {
  text-align: center;
  vertical-align: middle;
  background-color: rgb(41, 47, 65);
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}

#showBtn1 {
    position: absolute;
    top: 45%;
    left: 58%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    /* width: 12%; */
    /* font-size: 18px; */
}

#showBtn2 {
    position: absolute;
    top: 45%;
    left: 58%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
}

#showBtn3 {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
}

#showBtn4 {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    /* width: 22%; */
    /* font-size: 18px; */
}

#showBtn5 {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
}

#showBlur {
    filter: blur(14px);
    -webkit-filter: blur(14px); background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#showBlurCashback {
    filter: blur(6px);
    -webkit-filter: blur(6px); background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: linear-gradient(45deg, rgb(233, 233, 233) 25%, transparent 25%, transparent 50%, rgb(233, 233, 233) 50%, rgb(233, 233, 233) 75%, transparent 75%, #fff); */
}

.chart1-blur {
    background-image: url("~@/assets/img/blurbg.jpg");
}

.chart2-blur {
    background-image: url("~@/assets/img/blurbg.jpg");
}

.cashback-blur {
    background-image: url("~@/assets/img/blurbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.artikel {
    margin-right:20px!important;
}

/* a.card-artikel:link, a.card-artikel:visited {
    color: #000000;
}

a.card-artikel:hover, a.card-artikel:active {
    color: #000000;
} */

@media (min-width: 481px) and (max-width: 767px) {
  .cat {
    border-radius: 0px;
  }
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .info-box .cat {
    border-radius: 0px;
  } 
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
@media (max-width: 320px) {
  .info-box .cat {
    border-radius: 0px;
  } 
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
    #showBtn5 {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    #showBtn5 {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }
}

@media (max-width: 575px) {
    #showBtn1 {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }

    #showBtn2 {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }

    #showBtn5 {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }

    .artikel {
        margin-right:0px!important;
    }
}

@media (max-width: 400px) {
    #showBtn5 {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }
}
</style>